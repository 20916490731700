<template>
  <div class="page">
    <div v-if="$i18n.locale == 'zh'" class="plate_box">
      <div class="plate plate_1">
        <div class="plate_title">产品合作</div>
        <div class="plate_text">邮箱:</div>
        <div class="plate_text">Evelynchen@muugame.com</div>
        <div class="plate_arrow"></div>
      </div>
      <div class="plate plate_2">
        <div class="plate_title">市场合作</div>
        <div class="plate_text">邮箱:</div>
        <div class="plate_text">chackchi@muugame.com</div>
        <div class="plate_arrow"></div>
      </div>
      <div class="plate plate_3">
        <div class="plate_title">投诉及建议</div>
        <div class="plate_text">邮箱:</div>
        <div class="plate_text">customer-service@muugame.com</div>
        <div class="plate_arrow"></div>
      </div>
    </div>
    <div v-else class="plate_box">
      <div class="plate plate_1">
        <div class="plate_title">Product cooperation</div>
        <div class="plate_text">E-mail:</div>
        <div class="plate_text">bellawang@muugame.com</div>
        <div class="plate_arrow"></div>
      </div>
      <div class="plate plate_2">
        <div class="plate_title">Market co-operation</div>
        <div class="plate_text">E-mail:</div>
        <div class="plate_text">chackchi@muugame.com</div>
        <div class="plate_arrow"></div>
      </div>
      <div class="plate plate_3">
        <div class="plate_title">Domestic and foreign games issuance</div>
        <div class="plate_text">E-mail:</div>
        <div class="plate_text">customer-service@muugame.com</div>
        <div class="plate_arrow"></div>
      </div>
    </div>
    <img class="map" src="@/assets/img/lxwm_map.jpg" alt="">
  </div>
</template>
<script>

export default {
  data(){
    return {
      
    }
  },
  methods: {
    
  }
}
</script>
<style scoped>
.plate_box{
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
.plate{
  position: relative;
  padding: 0 20px;
  width: 310px;
  height: 440px;
  color: #fff;
  box-sizing: border-box;
  overflow: hidden;
}
.plate_1{
  background-color: #f26522;
}
.plate_2{
  background-color: #14a6a3;
}
.plate_3{
  background-color: #514742;
}
.plate_1 .plate_title{
  border-color: #f6822c;
}
.plate_2 .plate_title{
  border-color: #4ae3e3;
}
.plate_3 .plate_title{
  border-color: #685c55;
}
.plate_arrow{
  position: absolute;
  top: -70px;
  right: -50px;
  width: 100px;
  height: 100px;
  transform: rotateZ(30deg);
  opacity: 1;
}
.plate_1 .plate_arrow{
  background-color: #ffb15e;
}
.plate_2 .plate_arrow{
  background-color: #57c3c1;
}
.plate_3 .plate_arrow{
  background-color: #806e61;
}
.plate_title{
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 90px;
  font-size: 24px;
  border-bottom: 1px solid #fff;
}
.plate_text{
  margin: 30px 0;
  font-size: 15px;
}
.map{
  margin-top: 30px;
}
@media screen and (max-width: 1000px) {
  .plate_box{
    display: block;
  }
  .plate{
    margin-bottom: 20px;
    width: 100%;
    height: 250px;
  }
  .plate_title{
    font-size: 20px;
    height: 60px;
  }
}
</style>
